import React, { useMemo } from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Guides from '../components/Guides'
import SEO from '../components/SEO'
import { getSimplifiedPosts, slugify } from '../utils/helpers'
import config from '../utils/config'

import designPattern from '../../content/thumbnails/design-pattern.png'
import node from '../../content/thumbnails/node.png'
import basic from '../../content/thumbnails/ghibli.png'
import react from '../../content/thumbnails/react.png'
import golang from '../../content/thumbnails/golang.png'
import cleanCode from '../../content/thumbnails/clean-code.png'
import git from '../../content/thumbnails/git.png'
import db from '../../content/thumbnails/db.png'
import google from '../../content/thumbnails/google.png'
import rsch from '../../content/thumbnails/jekyll.png'
import cicd from '../../content/thumbnails/cicd.png'

const manuals = [
  { name: 'Basic', image: basic, url: '/categories/basic' },
  { name: 'React', image: react, url: '/categories/react' },
  { name: 'Node', image: node, url: '/categories/node' },
  { name: 'Golang', image: golang, url: '/categories/golang' },
  { name: 'Code', image: cleanCode, url: '/categories/clean-code' },
  { name: 'Pattern', image: designPattern, url: '/categories/pattern' },
  { name: 'GIT', image: git, url: '/categories/git' },
  { name: 'Database', image: db, url: '/categories/database' },
  { name: 'Research', image: rsch, url: '/categories/research' },
  { name: 'GCloud', image: google, url: '/categories/google-cloud' },
  { name: 'CI/CD', image: cicd, url: '/categories/ci-cd' },
]

export default function GuidesIndex({ data }) {
  const posts = data.allMarkdownRemark.edges
  const simplifiedPosts = useMemo(
    () => getSimplifiedPosts(posts, { thumbnails: true }),
    [posts]
  )

  {
    console.log('datapost', data)
  }

  return (
    <Layout>
      <Helmet title={`Guides | ${config.siteTitle}`} />
      <SEO />
      <header>
        <div className="container">
          <h1>🧭 Guides.</h1>
          <p className="subtitle">
            Saya mengumpulkan per kategori artikel, jadi teman-teman biar enak
            bisa fokus ke satu ketegori 😇.
          </p>
          <br /> <br />
          <div className="instruction-manuals">
            {manuals.map((manual) => (
              <Link to={manual.url} key={manual.url}>
                <img src={manual.image} alt={manual.name} />
                <h3>{manual.name}</h3>
              </Link>
            ))}
          </div>
        </div>
      </header>
      <div className="container">
        <Guides data={simplifiedPosts} includeTime />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query GuidesQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: "Guides" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            # description
            tags
            categories
            # topic
            thumbnail {
              childImageSharp {
                fixed(width: 100, height: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
